import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
  Link,
  Alert,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as s from './styled';
import { Navigate, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import API from 'lib/api';
import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { Logo } from 'components/Logo';
import { useNotification } from 'hooks/notification';
import { REGEX_EMAIL } from 'constants/regex';

type Inputs = {
  email: string;
  name: string;
  password: string;
};

export const RegisterForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Inputs>({ defaultValues: { email: '', password: '', name: '' }, mode: 'onChange' });
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';

  const [showPassword, setShowPassword] = useState(false);
  const { showNotification } = useNotification();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const clearError = () => {
    if (errorMessage) setErrorMessage('');
  };

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      const { request } = await API.register(formData);
      if (request.status === 409) {
        setErrorMessage('Данный email уже зарегистрирован в системе!');
      } else {
        showNotification('Регистрация прошла успешно!', 'success');
        navigate(from, { replace: true });
      }
    } catch (error) {}
  };

  if (user) return <Navigate to="/" />;

  return (
    <s.Container>
      <s.Wrapper>
        <Paper elevation={1} sx={{ p: 5 }}>
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: {
                xs: '200px',
                sm: 'auto',
              },
              margin: 'auto',
            }}
          >
            <Logo width={120} />
            <h3 style={{ marginTop: 4 }}>Регистрация на сайте</h3>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Неверный формат для email',
                },
                required: 'Поле обязательно для заполнения',
              }}
              render={({ field }) => (
                <TextField
                  error={!!errors.email?.message}
                  fullWidth
                  margin="normal"
                  label="Email"
                  variant="outlined"
                  helperText={errors.email?.message}
                  {...field}
                  onChange={(val) => {
                    field.onChange(val);
                    clearError();
                  }}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                minLength: { value: 8, message: 'Пароль должен содержать минимум 8 символов' },
                required: 'Поле обязательно для заполнения',
              }}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <InputLabel
                    error={!!errors.password?.message}
                    htmlFor="outlined-adornment-password"
                  >
                    Пароль
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    margin="dense"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Пароль"
                    error={!!errors.password?.message}
                    fullWidth
                    {...field}
                    onChange={(val) => {
                      field.onChange(val);
                      clearError();
                    }}
                  />
                  {errors.password?.message && (
                    <FormHelperText error={!!errors.password?.message} id="password-helper-text">
                      {errors.password?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  error={!!errors.name?.message}
                  fullWidth
                  margin="dense"
                  label="Ваше имя"
                  variant="outlined"
                  helperText={errors.name?.message}
                  {...field}
                  onChange={(val) => {
                    field.onChange(val);
                    clearError();
                  }}
                />
              )}
            />
            <Box
              sx={{
                textAlign: 'right',
                marginTop: 2,
              }}
            >
              <s.Recovery>
                <RouterLink to="/recovery">Забыли пароль?</RouterLink>
              </s.Recovery>
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                marginTop: 2,
              }}
            >
              <Button fullWidth size="large" variant="contained" type="submit" disabled={!isValid}>
                Зарегистрироваться
              </Button>
            </Box>
            {errorMessage && (
              <Alert sx={{ marginTop: 2 }} severity="error">
                {errorMessage}
              </Alert>
            )}
          </form>
        </Paper>
        <Box sx={{ textAlign: 'center', marginTop: 2 }}>
          <Link component={RouterLink} to="/login">
            Вход на сайт
          </Link>
        </Box>
      </s.Wrapper>
    </s.Container>
  );
};
