import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  TextField,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as s from './styled';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import API from 'lib/api';
import { useState } from 'react';
import { useAuth } from 'hooks/auth';
import { setStorageTokens } from 'lib/auth';
import { REGEX_EMAIL } from 'constants/regex';
import { Logo } from 'components/Logo';
import { useNotification } from 'hooks/notification';

type Inputs = {
  email: string;
  password: string;
};

export const LoginForm = () => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
  } = useForm<Inputs>({
    defaultValues: { email: '', password: '' },
    criteriaMode: 'all',
    mode: 'onChange',
  });
  const { setUser } = useAuth();
  const { showNotification } = useNotification();

  const navigate = useNavigate();
  const location = useLocation();

  const from = location.state?.from?.pathname || '/';

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    try {
      const { data, request } = await API.login(formData);

      if (request.status < 400) {
        setStorageTokens(data.access_token, data.refresh_token);
        setUser(data.user);
        navigate(from, { replace: true });
      } else {
        const { message } = JSON.parse(request.response);
        showNotification(message, 'error');
        setError('email', { type: '400', message: 'Email или пароль введен неверно' });
        setError('password', { type: '400', message: 'Email или пароль введен неверно' });
      }
    } catch (error) {}
  };
  return (
    <s.Container>
      <s.Wrapper>
        <Paper elevation={1} sx={{ p: 5 }}>
          <Box
            sx={{
              textAlign: 'center',
              maxWidth: {
                xs: '200px',
                sm: 'auto',
              },
              margin: 'auto',
            }}
          >
            <Logo width={120} />
            <h3 style={{ marginTop: 4 }}>Вход на сайт</h3>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              rules={{
                pattern: {
                  value: REGEX_EMAIL,
                  message: 'Неверный формат для email',
                },
                required: 'Поле обязательно для заполнения',
              }}
              render={({ field }) => (
                <TextField
                  error={!!errors.email?.message}
                  fullWidth
                  margin="normal"
                  label="Email"
                  variant="outlined"
                  onFocus={() => clearErrors('email')}
                  helperText={errors.email?.message}
                  {...field}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                minLength: { value: 8, message: 'Пароль должен содержать минимум 8 символов' },
                required: 'Поле обязательно для заполнения',
              }}
              render={({ field }) => (
                <FormControl sx={{ width: '100%' }} variant="outlined">
                  <InputLabel
                    error={!!errors.password?.message}
                    htmlFor="outlined-adornment-password"
                  >
                    Пароль
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Пароль"
                    error={!!errors.password?.message}
                    fullWidth
                    onFocus={() => clearErrors('password')}
                    {...field}
                  />
                  {errors.password?.message && (
                    <FormHelperText error={!!errors.password?.message} id="password-helper-text">
                      {errors.password?.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
            <Box
              sx={{
                textAlign: 'right',
                marginTop: 2,
              }}
            >
              <s.Recovery>
                <RouterLink to="/recovery">Забыли пароль?</RouterLink>
              </s.Recovery>
            </Box>
            <Box
              sx={{
                textAlign: 'right',
                marginTop: 2,
              }}
            >
              <Button fullWidth size="large" variant="contained" disabled={!isValid} type="submit">
                Войти
              </Button>
            </Box>
          </form>
        </Paper>
      </s.Wrapper>
    </s.Container>
  );
};
