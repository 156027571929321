import { Logo } from 'components/Logo';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box } from '@mui/material';

export const HomeHeader = () => (
  <div className="home-header">
    <div className="home-header-logo">
      <Logo />
    </div>
    <div className="tels">
      <Box sx={{ display: 'flex', mb: 1, '& a': { color: 'text.primary' } }}>
        <WhatsAppIcon sx={{ mr: 1, color: 'action.disabled' }} />
        <a href="https://wa.me/79585243333" target="_blank" rel="noreferrer">
          +7 958 5243333
        </a>
      </Box>
    </div>
  </div>
);
