import logoBlack from 'assets/logo_black.png';
import logo from 'assets/logo.png';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export const Logo: React.FC<{ width?: number }> = ({ width = 80 }) => {
  const { palette } = useTheme();
  return (
    <Link to="/">
      <img
        src={palette.mode === 'dark' ? logo : logoBlack}
        className="logo"
        width={width}
        alt="The Sha SM logo"
      />
    </Link>
  );
};
